@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

@import 'color.scss';

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Roboto Mono', monospace;
  color: $font;
  border-color: $acent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  ::selection {
    background: $acent;
  }
  
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: $acent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 2px;
  }
}

body {
  background: $background;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}