@import '../../Styles/helpers.scss';
@import '../../Styles/color.scss';

.mainView {

    @media screen and (min-width: 526px) {

    }

    &_contentWrapper {
        display: flex;
        flex-direction: column;
        width: vw(320px);
        transform: translateY(vw(-18px));

        @media screen and (min-width: 526px) {
            transform: translateY(-18px);
        }
    }

    &_contentToper {
        filter: drop-shadow(0px -8px 40px rgba($color: $acent, $alpha: 0.3));
        
        min-width: vw(320px);
        max-width: vw(320px);
        // max-height: vw(19px);

        @media screen and (min-width: 526px) {
            display: none;
        }

        path {
            min-width: inherit;
            max-width: inherit;
        }
    }

    &_content {
        position: relative;
        z-index: 10;
        background: $background;

        transform: translateY(vw(-1px));
        padding: 0px vw(16px);

        @media screen and (min-width: 526px) {
            padding: 20px calc(50% - 160px);
            transform: translateY(-1px);
        }
    }

    &_locationName {
        line-height: 100%;
        margin: 0px auto;
        text-align: center;

        font-size: vw(20px);
        margin-bottom: vw(16px);

        @media screen and (min-width: 526px) {
            font-size: 20px;
            margin-bottom: 16px;
        }
    }

    &_geoBlock {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        border: solid $acent;
        
        border-width: vw(1px);
        border-radius: vw(8px);
        height: vw(40px);
        margin-bottom: vw(16px);

        @media screen and (min-width: 526px) {
            border-width: 1px;
            height: 40px;
            border-radius: 8px;
            margin-bottom: 16px;
        }
    }

    &_geoLabel {
        position: absolute;
        font-weight: 700;
        background: $background;

        padding: 0px vw(4px);
        top: vw(-10px);
        left: vw(8px);
        font-size: vw(12px);

        @media screen and (min-width: 526px) {
            top: -10px;
            left: 8px;
            padding: 0px 4px;
            font-size: 12px;
        }
    }

    &_geoInput {
        background: none;
        border: none;
        width: 100%;
        height: 100%;

        padding: 0px vw(12px);

        @media screen and (min-width: 526px) {
            padding: 0px 12px;
        }
    }

    &_geoCopy {
        height: 100%;
        background: none;
        border: none;
        border-left: solid $acent;
        cursor: pointer;
        transition: background 0.3s ease-in-out;

        min-width: vw(112px);
        max-width: vw(112px);
        border-left-width: vw(1px);
        border-radius: 0px vw(8px) vw(8px) 0px;

        @media screen and (min-width: 526px) {
            min-width: 112px;
            max-width: 112px;
            border-left-width: 1px;
            border-radius: 0px 8px 8px 0px;

            &:hover {
                background: rgba($color: $acent, $alpha: 0.5);
            }
        }

        &:active {
            background: $acent;
        }
    }

    &_logBlock {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        gap: vw(8px);
        margin-bottom: vw(24px);
        max-height: vw(200px);

        @media screen and (min-width: 526px) {
            gap: 8px;
            margin-bottom: 24px;
            max-height: 200px;
        }
    }

    &_logItem {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        gap: vw(8px);

        @media screen and (min-width: 526px) {
            gap: 8px;
        }
    }

    &_logTime {
        font-weight: 700;
        line-height: 120%;
        white-space: nowrap;

        font-size: vw(12px);

        @media screen and (min-width: 526px) {
            font-size: 12px;
        }
    }

    &_logText {
        font-weight: 700;
        line-height: 120%;

        font-size: vw(12px);

        @media screen and (min-width: 526px) {
            font-size: 12px;
        }
    }

    &_logEmpty {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 120%;

        height: vw(100px);
        padding: vw(24px);

        @media screen and (min-width: 526px) {
            height: 100px;
            padding: 24px;
        }
    }

    &_yaRek {
        border: solid $acent;

        height: vw(100px);
        border-width: vw(1px);
        border-radius: vw(8px);
        margin-bottom: vw(12px);

        @media screen and (min-width: 526px) {
            height: 100px;
            border-width: 1px;
            border-radius: 8px;
            margin-bottom: 12px;
        }

        &:empty {
            display: none;
        }
    }

    &_footer {
        display: flex;
        align-items: center;
        
        gap: vw(8px);

        @media screen and (min-width: 526px) {
            gap: 8px;
        }
    }

    &_footerButton {
        border: solid $acent;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        width: 100%;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
        
        gap: vw(8px);
        padding: 0px vw(12px);
        height: vw(40px);
        border-radius: vw(8px);
        border-width: vw(1px);

        @media screen and (min-width: 526px) {
            gap: 8px;
            padding: 0px 12px;
            height: 40px;
            border-radius: 8px;
            border-width: 1px;
            
            &:hover {
                background: rgba($color: $acent, $alpha: 0.5);
            }
        }
    
            &:active {
                background: $acent;
            }
    }

    &_footerButton:active &_footerButtonIcon path{
        fill: $font;
    }
    
    @media screen and (min-width: 526px) {
        &_footerButton:hover &_footerButtonIcon path {
            fill: $font;
        }
    }

    &_footerButtonIcon {
        min-width: vw(16px);
        max-width: vw(16px);
        min-height: vw(16px);
        max-height: vw(16px);

        @media screen and (min-width: 526px) {
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-height: 16px;
        }

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    &_footerButtonText {
        white-space: nowrap;
        font-weight: 700;
        line-height: 100%;

        font-size: vw(10px);

        @media screen and (min-width: 526px) {
            font-size: 10px;
        }
    }

    &_notification {
        position: fixed;
        z-index: 10;
        width: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        
        padding: vw(16px);
        gap: vw(12px);

        @media screen and (min-width: 526px) {
            margin: 0px calc(50% - 160px);
            width: 320px;
            padding: 16px;
            gap: 12px;
        }
    }
    
    &_notificationText {
        border: solid $acent;
        background: rgba($color: $background, $alpha: 0.5);
        transition: opacity 0.5s ease-in-out;
        cursor: pointer;
        backdrop-filter: blur(5px);

        animation: notify-fade 0.5s ease-in-out 0s 1;

        padding: vw(8px) vw(12px);
        border-radius: vw(8px);
        border-width: vw(1px);
        font-size: vw(12px);

        @media screen and (min-width: 526px) {
            padding: 8px 12px;
            border-radius: 8px;
            border-width: 1px;
            font-size: 12px;
        }

        @keyframes notify-fade {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}