@import '../../Styles/helpers.scss';
@import '../../Styles/color.scss';

.radar {

    @media screen and (min-width: 526px) {
        
    }

    &_canvas {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        perspective: 600px;

        width: 100vw;
        height: vw(280px);
        max-height: vw(280px);

        @media screen and (min-width: 526px) {
            height: 280px;
            max-height: 280px;
        }

        & > * {
            margin-top: -100px;
        }
    }
    
    &_circle {
        position: absolute;
        border-radius: 50%;
        border: dashed $acent;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        
        transform: rotateX(70deg);
        
        border-width: vw(2px);
        
        @media screen and (min-width: 526px) {
            border-width: 2px;
        }

        &::before {
            display: block;
            transform-origin: left top;
            transform: translateX(50%);

            font-size: vw(12px);
            
            @media screen and (min-width: 526px) {
                font-size: 12px;
            }
        }
        
    
        &__100 {

            width: vw(580px);
            height: vw(580px);
            
            @media screen and (min-width: 526px) {
                width: 580px;
                height: 580px;
            }

            &::before {
                content: '100km';
            }
        }
        
        &__50 {

            width: vw(480px);
            height: vw(480px);
            
            @media screen and (min-width: 526px) {
                width: 480px;
                height: 480px;
            }

            &::before {
                content: '75km';
            }
        }
        
        &__20 {

            width: vw(380px);
            height: vw(380px);
            
            @media screen and (min-width: 526px) {
                width: 380px;
                height: 380px;
            }

            &::before {
                content: '50km';
            }
        }
        
        &__5 {

            width: vw(280px);
            height: vw(280px);
            
            @media screen and (min-width: 526px) {
                width: 280px;
                height: 280px;
            }

            &::before {
                content: '25km';
            }
        }
        
        &__1 {

            width: vw(180px);
            height: vw(180px);

            @media screen and (min-width: 526px) {
                width: 180px;
                height: 180px;
            }

            &::before {
                content: '5km';
            }
        }
    }
    
    &_axis {
        position: absolute;
        height: 0px;
        border-top: dashed rgba($color: $acent, $alpha: 0.3);
        
        border-top-width: vw(2px);
        width: vw(580px);

        @media screen and (min-width: 526px) {
            border-top-width: 2px;
            width: 580px;
        }
    
        &__x {
            border-radius: 50%;
            transform: translateY(64px);
            // width: vw(320px);
            height: vw(120px);
            
            @media screen and (min-width: 526px) {
                // width: 320px;
                height: 120px;
            }
        }
        
        &__y {
            transform: rotateX(70deg) rotateZ(90deg);
            
            @media screen and (min-width: 526px) {
                
            }
        }
    }
    
    &_line {
        position: absolute;
        transform-origin: left top;
        background: linear-gradient(45deg, rgba($color: $acent, $alpha: 1) 0%, rgba($color: $acent, $alpha: 0) 50%);
        border-left: solid $acent;
        transform: translate3d(50%, 50%, 0px) rotateX(70deg);

        animation: rotate-radar 10s linear 0s infinite;

        border-left-width: 1px;
        width: vw(380px);
        height: vw(380px);
        border-radius: 0px 0px vw(380px) 0px;
        
        @media screen and (min-width: 526px) {
            width: 380px;
            height: 380px;
            border-radius: 0px 0px 380px 0px;
        }

        @keyframes rotate-radar {
            0% {
                transform: translate3d(50%, 50%, 0px) rotateX(70deg) rotateZ(0deg);
            }
            
            100% {
                transform: translate3d(50%, 50%, 0px) rotateX(70deg) rotateZ(360deg);
            }
        }
    }
    
    &_marker {
        margin-top: vw(-128px);
        
        @media screen and (min-width: 526px) {
            margin-top: -128px;
        }
    }
    
    &_compas {
        position: absolute;
        font-weight: 700;
        line-height: 100%;

        font-size: vw(12px);
        
        @media screen and (min-width: 526px) {
            font-size: 12px;
        }
    
        &__n {
            transform: translate3d(-100%, vw(-52px), 0px);
            
            @media screen and (min-width: 526px) {
                transform: translate3d(-100%, -52px, 0px);
                
            }
        }
        
        &__e {
            transform: translate3d(vw(148px), 0px, 0px);
            
            @media screen and (min-width: 526px) {
                transform: translate3d(148px, 0px, 0px);
            }
        }
        
        &__s {
            transform: translate3d(-100%, vw(144px), 0px);
            
            @media screen and (min-width: 526px) {
                transform: translate3d(-100%, 144px, 0px);
                
            }
        }
        
        &__w {
            transform: translate3d(vw(-148px), 0px, 0px);
            
            @media screen and (min-width: 526px) {
                transform: translate3d(-148px, 0px, 0px);
            }
        }
    }
    
    &_points {
        position: absolute;
        
        @media screen and (min-width: 526px) {
            
        }
    }
}


.radarPoint {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;

    gap: vw(4px);
        
    @media screen and (min-width: 526px) {
        gap: 4px;
    }
    
    &_marker {
        width: vw(18px);
        height: vw(18px);
        
        @media screen and (min-width: 526px) {
            width: 18px;
            height: 18px;
        }
    }
    
    &_time {
        line-height: 100%;
        white-space: nowrap;

        font-size: vw(10px);
        
        @media screen and (min-width: 526px) {
            font-size: 10px;
        }
    }
}